import * as React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { StaticImage } from "gatsby-plugin-image";

const IndexPage = () => {
  return (
    
      <Layout>
        <Seo
        title="Hi."
        description="Creating sustainable tools for modern filmmakers"
        />
      <div className="h-screen w-screen flex flex-col justify-between place-content-around items-center  max-w-full herobg bg-cover bg-fixed bg-center bg-gray-900" >
        <span></span>
        <h1 className="hidden">Heinrig Filmtechnik</h1>
        <div className="flex h-1/3 items-center mx-auto my-20">
        <h2 className="flex-auto text-4xl lg:text-6xl text-right font-semibold p-2 lg:p-10 text-white">
          sustain<br/>able<br/> tools
        </h2>
        <h2 className="flex-auto text-4xl lg:text-6xl text-center font-semibold p-2 lg:p-10 text-white">
          for
        </h2>
        <h2 className="flex-auto text-4xl lg:text-6xl text-left font-semibold p-2 lg:p-10  text-white">
          modern <br/>film<br/>makers
        </h2>
        </div>
        <p className="justify-self-end p-7 pb-28 lg:px-20 lg:w-1/2 xl:w-1/3 lg:text-lg text-white">filmmakers need more than just a new camera. 
            we need peripherals which are easy to use and sustainable both ecologically and financially.

            <br /><br />that’s what we do.</p>
      </div>




      <div className="h-screen w-screen flex flex-col max-w-full">
        <h3 className="font-semibold text-lg text-center p-3">our products</h3>
          <Link to="/products/quicksticks/" className="m-auto w-4/5 md:w-3/4 xl:w-2/3 2xl:w-1/2 py-5 flex flex-col lg:flex-row items-center justify-center lg:space-x-10">
          <StaticImage src="../images/quicksticks/IMG_2021.jpeg" className="rounded-xl w-full" alt="slate"/>
            <div className="flex flex-col items-center lg:items-baseline ">
              <h2 className="text-4xl lg:text-6xl text-center font-semibold lg:text-left">quicksticks</h2>
              <h3 className="font-medium text-xl lg:text-3xl text-center lg:text-left">convenient, eco-friendly custom engraved slate</h3>
              <p className="text-center py-5 lg:text-left">your design on it, made responsibly.<br/>
              a slate with a few tricks up its sticks.</p>
              <div className="button">more</div>
            </div>
            </Link>
        <p className="align-text-bottom p-10 text-center">more products to come,<br />
          pretty cool ones btw.</p>
      </div>




      <div className="h-auto w-screen bg-black max-w-full">
      <h3 className="font-semibold text-lg text-center p-3 text-white">our philosophy</h3>
      <div className="m-auto w-4/5 lg:w-full py-5 flex flex-col lg:flex-row lg:justify-between items-center place-content-around pb-10">
          <StaticImage src="../images/index/p&g.jpg" alt="Philipp and Georg" className="rounded-2xl lg:rounded-none w-full lg:w-1/2 lg:py-40 "/>
          <div className="w-full flex flex-col items-center max-w-xl lg:mx-20 2xl:mx-40">
            <h2 className="text-4xl lg:text-6xl font-semibold text-white pt-5 w-full">making film production just <br />a little bit better.</h2>
            <p className="py-5 text-white w-full">our goal is to help others to be creative, 
            with tech that just works. <br />
            additionally, we try to make our tech as sustainable as possible.
            <br /><br />
            
            we’re filmmakers as well, and we couldn’t find the tools we wanted, so we rushed to our garage and made them ourselves. 
            <br /><br />
            learn more about us and why we do this.
            </p>
            <Link to="/philosophy/" className="button">read more</Link>
          </div>
        </div>
      </div>




      <div className="w-screen max-w-full">
        <h3 className="font-semibold text-lg text-center p-3">socials</h3>
        <div className="m-auto w-4/5 md:w-1/2 xl:w-1/4 py-5 flex flex-col items-center pb-10">
          <StaticImage src="../images/index/socialadheinrig.png" className="rounded-xl w-full" alt="slate"/>
          <h2 className="text-3xl text-center font-semibold">keep up with us</h2>
          <p>there’s so much more to come.  we can’t wait to tell you about our future plans and projects, and we’d love you to join our journey.
          </p>
          <p className="text-center pt-10 pb-3">You can check us out on</p>
          <a href="https://www.instagram.com/heinrig.tech/" className="button">Instagram</a>

        </div>
        

      </div>
      
      </Layout>
  )
}

export default IndexPage
